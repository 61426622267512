import React from 'react';
import SbEditable from 'storyblok-react';
import ReactMarkdown from 'react-markdown';
import { FixedImage } from './image';

const Customers = ({ blok }) => (
    <SbEditable content={blok}>
        <section className="slice bg-section-primary">
            <div className="container">
                <div className="mb-5 text-center">
                    <h3 className=" mt-4">{blok.heading}</h3>
                    <div className="fluid-paragraph mt-3">
                        <ReactMarkdown source={blok.subheading} className="lead lh-180" />
                    </div>
                </div>
                <div>
                    <div className="row justify-content-start">
                        {blok.customer_icons.map(icon => (
                            <div key={icon._uid} className="client col-lg-2 col-md-3 col-4 py-3">
                                <FixedImage attributes={{ quality: 75, height: 100, width: 100 }} className="customer-icon" img={icon.img} title={icon.name} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    </SbEditable>
);
export default Customers;
