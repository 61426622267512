import React from 'react';
import SbEditable from 'storyblok-react';
import Swiper from 'react-id-swiper';
import ReactMarkdown from 'react-markdown';

const Testimonial = ({ blok }) => {
    const params = {
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
    };
    return (
        <SbEditable content={blok}>
            <section className="slice slice-lg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <Swiper {...params}>
                                {blok.quotes.map(quote => (
                                    <div className="mb-5" key={quote._uid}>
                                        <div className="text-center">
                                            <ReactMarkdown source={quote.text} className="quote-markdown h2 lh-160 text-gray font-italic font-weight-300" />
                                            <div className="text-center mt-4">
                                                <h3 className="h5">{quote.author}</h3>
                                                <span className="lead text-muted">{quote.source}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </SbEditable>
    );
};

export default Testimonial;
