import React from 'react';
import SbEditable from 'storyblok-react';

export default class Events extends React.Component {
    render() {
        const { blok } = this.props;
        return (
            <SbEditable content={blok}>
                <section className="slice slice-lg bg-section-secondary">
                    <div className="container">
                        <div className="row row-grid">
                            {blok.events.map(event => (
                                <div className="col-lg-6" key={event._uid}>
                                    <div className="card mb-4">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <span className="d-block text-center text-uppercase text-warning">{event.month}</span>
                                                    <span className="d-block text-center h1 mb-0">{event.day}</span>
                                                </div>
                                                <div className="col-md-10 text-center text-md-left">
                                                    <h3 className="h5">{event.name}</h3>
                                                    <p className="mb-0">
                                                        {event.description} {' '}
                                                        {event.url && event.url.url && (
                                                            <a href={event.url.url} className="font-weight-bold" rel="nofollow" target="_blank">mehr</a>
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </SbEditable>
        );
    }
}
