import React from 'react';
import SbEditable from 'storyblok-react';
import BackgroundImage from 'gatsby-background-image';
import { StaticQuery, graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import ButtonBar from './buttonBar';

const TeaserOne = ({ blok }) => (
    <StaticQuery
        query={graphql`
            query {
                desktop: file(relativePath: { eq: "bg_teaser_one.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 4160) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `}
        render={data => {
            const imageData = data.desktop.childImageSharp.fluid;
            return (
                <SbEditable content={blok}>
                    <BackgroundImage className="slice slice-lg bg-cover bg-size--cover teaser-one" fluid={imageData} Tag="section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="card py-5 px-4 box-shadow-3">
                                        <div className="card-body">
                                            <h6 className="h2">
                                                <ReactMarkdown source={blok.heading} />
                                            </h6>
                                            <ReactMarkdown source={blok.text} className="lead lh-180 mt-4"></ReactMarkdown>
                                            <div className="btn-container mt-5">
                                                <ButtonBar blok={blok} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </BackgroundImage>
                </SbEditable>
            );
        }}
    />
);

export default TeaserOne;
