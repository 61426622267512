import React from 'react';
import SbEditable from 'storyblok-react';
import Img from 'gatsby-image';
import { getFixedGatsbyImage, getFluidGatsbyImage } from 'gatsby-storyblok-image';

export const FixedImage = ({ img, className = '', title, attributes = {} }) => {
    const fixedProps = getFixedGatsbyImage(img, attributes);
    return (
        <SbEditable content={img}>
            <Img title={title} className={className} fixed={fixedProps} />
        </SbEditable>
    );
};

export const FluidImage = ({ img, className = '', title, attributes = {} }) => {
    const fluidProps = getFluidGatsbyImage(img, attributes);
    return (
        <SbEditable content={img}>
            <Img title={title} className={className} fluid={fluidProps} />
        </SbEditable>
    );
};
