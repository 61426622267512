import React from 'react';
import SbEditable from 'storyblok-react';
import ReactMarkdown from 'react-markdown';

const Datenschutz = ({ blok }) => (
    <SbEditable content={blok}>
        <section className="slice">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center mt-3 mb-3">
                        <h1>{blok.heading}</h1>
                    </div>
                    <div className="col-12">
                        <span className="lead lh-180">
                            <ReactMarkdown source={blok.text} />
                        </span>
                    </div>
                </div>
            </div>
        </section>
    </SbEditable>
);

export default Datenschutz;
