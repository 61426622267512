import React from 'react';
import { Link } from 'gatsby';

const GatsbyLink = ({ icon, name, btnColor, text, link }) => {
    return (
        <Link
            to={`/${link === undefined ? '' : link}`}
            className={`btn-${btnColor} ${icon && `btn-icon`} btn rounded-pill hover-translate-y-n3 mr-sm-4 scroll-me mb-1`}>
            {icon && (
                <span className="btn-inner--icon">
                    <i className={icon} />
                </span>
            )}
            <span className="btn-inner--text">{text}</span>
        </Link>
    );
};

export default GatsbyLink;
