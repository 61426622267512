import React from 'react';
import SbEditable from 'storyblok-react';
import ButtonBar from '../buttonBar';
import ReactMarkdown from 'react-markdown';
import { FixedImage } from '../image';

const ContactItems = ({ blok }) => (
    <SbEditable content={blok}>
        <section id="contact" className="slice slice-xl pb-0 bg-section-primary">
            <div className="container d-flex align-items-end px-0 pt-4">
                <div className="col">
                    <div className="row align-items-center justify-content-around">
                        <div className="col-lg-6 text-center text-lg-left">
                            <h1>{blok.heading}</h1>
                            <ReactMarkdown source={blok.text} className="lead mt-4" />
                            <ButtonBar blok={blok} />
                        </div>
                        <div className="d-flex col-lg-6 mt-5 mt-lg-0 align-self-end">
                            {blok.img && <FixedImage attributes={{ height: 600, quality: 75 }} className="img-fluid img-center" img={blok.img} />}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </SbEditable>
);

export default ContactItems;
