import React from 'react';
import SbEditable from 'storyblok-react';
import ExternalLink from './link_types/external_link';
import GatsbyLink from './link_types/gatsby_link';

export default class ButtonBar extends React.Component {
    render() {
        const { blok } = this.props;
        return (
            <SbEditable content={blok}>
                <div className="mt-5">
                    {blok.buttonBar.map(button => (
                        <React.Fragment key={button._uid}>
                            {button.ext_link ? (
                                <ExternalLink
                                    link={button.link.cached_url}
                                    btnColor={button.color}
                                    icon={button.icon}
                                    hasIcon={button.has_icon}
                                    text={button.text}
                                    isMail={button.is_mail}
                                    isPhone={button.is_phone}
                                />
                            ) : (
                                <GatsbyLink
                                    link={button.link.cached_url}
                                    btnColor={button.color}
                                    icon={button.icon}
                                    hasIcon={button.has_icon}
                                    text={button.text}
                                />
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </SbEditable>
        );
    }
}
