import React from 'react';
import SbEditable from 'storyblok-react';
import ButtonBar from './buttonBar';
import ReactMarkdown from 'react-markdown';
import HeaderShape from '../images/header_shape.svg';

const Header = ({ blok }) => (
    <SbEditable content={blok}>
        <section className="slice slice-xl bg-gradient-primary header-bg">
            <div className="pt-7 position-absolute middle right-0 col-lg-7 col-xl-6 d-none d-lg-block zindex-102">
                <figure className="w-100" style={{ maxWidth: 1000 }}>
                    <img alt="header-shape" src={blok.right_img} className="svg-inject img-fluid" style={{ height: 1000 }} />
                </figure>
            </div>
            <div className="container py-5 pt-lg-6 d-flex align-items-center position-relative zindex-100">
                <div className="col">
                    <div className="row">
                        <div className="col-lg-5 col-xl-6 text-center text-lg-left">
                            <div className="d-none d-lg-block mb-4"></div>
                            <div>
                                <h2 className="text-white mb-4">
                                    <span className="display-4 font-weight-light">{blok.heading}</span>
                                    <span className="d-block">
                                        <strong className="font-weight-light">{blok.subheading}</strong>
                                    </span>
                                </h2>
                                <ReactMarkdown source={blok.text} className="lead text-white" />
                            </div>
                            <ButtonBar blok={blok} />
                        </div>
                    </div>
                </div>
            </div>
            <figure className="wave-shape">
                <img alt="header-shape" src={HeaderShape} />
            </figure>
        </section>
    </SbEditable>
);

export default Header;
