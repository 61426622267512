import React from 'react';
import SbEditable from 'storyblok-react';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';

const Features = ({ blok }) => (
    <SbEditable content={blok}>
        <div id="leistungen" className="bg-section-secondary mt-5">
            <div className="pt-5 text-center">
                <h2>{blok.features_heading}</h2>
            </div>
            {blok.items.map((item, i) => (
                <section key={item._uid} className="slice slice-lg bg-section-secondary">
                    <div className="container">
                        <div className="row row-grid justify-content-around align-items-center">
                            <div className={`col-lg-6 ${i % 2 === 0 ? 'order-lg-1' : ''}`}>
                                <img alt="placeholder" src={item.img} className="img-fluid img-center" />
                            </div>
                            <div className="col-lg-5">
                                <h5 className="h3 mt-3">{item.heading}</h5>
                                <ReactMarkdown source={item.text} className="lead mt-4 mb-5" />
                                <Link to={item.link.url} className="link link-underline-primary">
                                    {item.link_text}
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            ))}
        </div>
    </SbEditable>
);

export default Features;
