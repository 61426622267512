import React from 'react';
import Components from './components.js';
import SEO from './seo';

const Page = ({ blok }) => (
    <div>
        <SEO title={blok.title} description={blok.description} />
        {blok.body &&
            blok.body.map(blok =>
                React.createElement(Components(blok.component), {
                    key: blok._uid,
                    blok: blok,
                })
            )}
    </div>
);

export default Page;
