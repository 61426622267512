import React from 'react';
import SbEditable from 'storyblok-react';
import ReactMarkdown from 'react-markdown';

class JobOffers extends React.Component {
    state = {
        opened: [],
    };

    toggle = (e, id) => {
        this.setState(state => ({
            [id]: !state[id],
        }));
        e.preventDefault();
    };

    isOpen = id => {
        return this.state[id] || false;
    };

    render() {
        const { blok } = this.props;
        return (
            <SbEditable content={blok}>
                <section className="slice slice-lg bg-gradient-primary">
                    <span className="tongue tongue-top">
                        <i className="fas fa-angle-up"></i>
                    </span>
                    <div className="container-fluid">
                        <div className="mb-5 text-center">
                            <h3 className="text-white mt-4">{blok.heading}</h3>
                            <div className="fluid-paragraph mt-3">
                                <p className="lead lh-180 text-white">{blok.subheading}</p>
                            </div>
                        </div>
                        {blok.job_offer.map(offer => (
                            <React.Fragment key={offer._uid}>
                                <div className="row offer justify-content-center table-hover table-scale--hover table-cards align-items-center">
                                    <div className="col-md-8 col-12">
                                        <table className="table-offer">
                                            <tbody className="d-flex align-items-center justify-content-center w-100 mb-2 flex-column">
                                                <tr className="w-100" onClick={e => this.toggle(e, offer._uid)} style={{ cursor: 'pointer' }}>
                                                    <th>
                                                        <div className="media align-items-center">
                                                            <div>
                                                                <span className={`avatar bg-${offer.avatar_color} text-white mr-4`}>{offer.avatar}</span>
                                                            </div>
                                                            <div className="media-body">
                                                                <span className="h5 mb-0">{offer.beschreibung}</span>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <td className="konditionen">
                                                        <span className="h6">{offer.konditionen}</span>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                                <tr className={this.isOpen(offer._uid) || 'd-none'}>
                                                    <th scope="row" colSpan="3" className="mt-2">
                                                        {offer.wir && (
                                                            <>
                                                                <span className="h6">Deine Aufgaben</span>
                                                                <ReactMarkdown source={offer.wir} />
                                                            </>
                                                        )}

                                                        {offer.du && (
                                                            <>
                                                                <span className="h6">Dein Profil</span>
                                                                <ReactMarkdown source={offer.du} />
                                                            </>
                                                        )}
                                                    </th>
                                                </tr>
                                                <tr className="table-divider"></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                    {blok.link_text && (
                        <div className="text-center mt-5">
                            <a href={blok.link_href} className="text-white">
                                <i className="fas fa-angle-right mr-2"></i>
                                {blok.link_text}
                            </a>
                        </div>
                    )}
                </section>
            </SbEditable>
        );
    }
}

export default JobOffers;
