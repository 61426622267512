module.exports = {
    siteMetadata: {
        siteUrl: `https://www.201created.de/`,
        title: `201CREATED - Softwareentwicklung bei Heilbronn`,
        description: `Wir bieten professionelle Softwareentwicklung im Webumfeld bei Heilbronn. Wir sind Spezialisten für Domain Driven Design, React, Symfony.`,
        author: `201CREATED GmbH`,
    },
    plugins: [
        {
            resolve: `gatsby-plugin-sitemap`,
            options: {
                exclude: ['/editor'],
            },
        },
        `gatsby-plugin-sass`,
        {
            resolve: 'gatsby-source-storyblok',
            options: {
                accessToken: 'L1VKDM4SfZnvMCIpuCXUJgtt',
                homeSlug: 'home',
                version: process.env.NODE_ENV === 'production' ? 'published' : 'draft',
            },
        },
        `gatsby-plugin-react-helmet`,
        {
            resolve: `gatsby-source-filesystem`,
            options: {
                name: `images`,
                path: `${__dirname}/src/images`,
            },
        },
        `gatsby-plugin-sharp`,
        `gatsby-transformer-sharp`,
        {
            resolve: `gatsby-plugin-manifest`,
            options: {
                name: `201CREATED`,
                short_name: `201`,
                start_url: `/`,
                background_color: `#663399`,
                theme_color: `#663399`,
                display: `minimal-ui`,
                icon: `src/images/201_logo_white.svg`,
                crossOrigin: `use-credentials`,
            },
        },
        {
            resolve: `gatsby-plugin-google-analytics`,
            options: {
                trackingId: 'UA-143325952-1',
                // Defines where to place the tracking script - `true` in the head and `false` in the body
                head: false,
                // Setting this parameter is optional
                anonymize: true,
                // Setting this par0ameter is also optional
                respectDNT: true,
                cookieDomain: 'www.201created.de',
            },
        },
    ],
};
