import React from 'react';
import Components from '../components/components.js';
import Navigation from '../components/global/navigation.js';
import Footer from '../components/global/footer.js';
import Contact from '../components/global/contact.js';

import '../purpose/assets/libs/@fortawesome/fontawesome-free/css/all.min.css';
import '../purpose/resources/scss/purpose.scss';

class StoryblokEntry extends React.Component {
    static getDerivedStateFromProps(props, state) {
        if (state.story.uuid === props.pageContext.story.uuid) {
            return null;
        }

        return StoryblokEntry.prepareStory(props);
    }

    static prepareStory(props) {
        const story = Object.assign({}, props.pageContext.story);
        const global = Object.assign({}, props.pageContext.global);
        story.content = JSON.parse(story.content);
        global.content = JSON.parse(global.content);

        return { story, global };
    }

    constructor(props) {
        super(props);

        this.state = StoryblokEntry.prepareStory(props);
    }

    render() {
        let content = this.state.story.content;
        let global = this.state.global.content;

        const withContact = content.with_contact || false;

        return (
            <div>
                <Navigation blok={global}></Navigation>
                {React.createElement(Components(content.component), { key: content._uid, blok: content })}
                {withContact && <Contact blok={global} />}
                <Footer blok={global}></Footer>
            </div>
        );
    }
}

export default StoryblokEntry;
