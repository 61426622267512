import React from 'react';
import SbEditable from 'storyblok-react';

const FeaturesText = ({ blok }) => (
    <SbEditable content={blok}>
        <section className="slice slice-lg bg-section-secondary">
            <div className="container">
                <div className="mb-5 text-center">
                    <h3 className="mt-4">{blok.headline}</h3>
                    <div className="fluid-paragraph mt-3">
                        <p className="lead lh-180">{blok.lead}</p>
                    </div>
                </div>
                <div className="row">
                    {blok.items.map((item, i) => (
                        <div className="col-lg-3 col-sm-6" key={item._uid}>
                            <div className="mb-4 text-center text-sm-left">
                                <div className="pt-5 pb-3">
                                    <div className="icon text-primary">
                                        <i className={item.icon}></i>
                                    </div>
                                </div>
                                <h5 className="">{item.headline}</h5>
                                <p className=" mt-2 mb-0">{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    </SbEditable>
);

export default FeaturesText;
