import ComponentNotFound from './component_not_found';
import Navigation from './global/navigation';
import NavItems from './global/nav_items';
import Header from './header';
import RoundedHeader from './header_round_shape';
import Page from './page';
import Features from './features';
import FeaturesText from './features_text';
import Customers from './customers';
import Team from './team';
import Footer from './global/footer';
import FooterItems from './global/footer_items';
import Contact from './global/contact';
import ContactItems from './global/contact_items';
import JobOffers from './job_offers';
import Testimonial from './testimonial';
import Events from './events';
import TeaserOne from './teaser_one';
import TeaserTwo from './teaser_two';
// import Post from './blog/post';
// import PostsPreview from './blog/posts_preview';

import ButtonBar from './buttonBar';
import Impressum from './impressum';
import Datenschutz from './datenschutz';

const ComponentList = {
    navigation: Navigation,
    nav_items: NavItems,
    header: Header,
    header_round_shape: RoundedHeader,
    page: Page,
    features: Features,
    features_text: FeaturesText,
    customers: Customers,
    team: Team,
    contact: Contact,
    contact_items: ContactItems,
    footer: Footer,
    footer_items: FooterItems,
    buttonBar: ButtonBar,
    impressum: Impressum,
    datenschutz: Datenschutz,
    job_offers: JobOffers,
    testimonial: Testimonial,
    events: Events,
    teaser_one: TeaserOne,
    teaser_two: TeaserTwo,
    // post: Post,
    // posts_preview: PostsPreview,
};

const Components = type => {
    if (typeof ComponentList[type] === 'undefined') {
        return ComponentNotFound;
    }
    return ComponentList[type];
};

export default Components;
