import React from 'react';
import SbEditable from 'storyblok-react';
import ReactMarkdown from 'react-markdown';
import RoundShape from '../purpose/assets/img/svg/shapes/curve-1.svg';
import ButtonBar from './buttonBar';

const RoundedHeader = ({ blok }) => (
    <SbEditable content={blok}>
        <section className="slice slice-lg bg-primary header-round-shape">
            <div className="container pt-2 pt-lg-4">
                <div className="row">
                    <div className="col-lg-7">
                        <h1 className="display-4 text-white lh-110 text-center text-md-left">{blok.heading}</h1>
                        <div className="lead mb-4 text-white mt-4">
                            <ReactMarkdown source={blok.text} />
                        </div>
                        <div className="mt-5">{blok.buttonBar && <ButtonBar blok={blok} />}</div>
                    </div>
                </div>
            </div>
            <div className="shape-container bg-primary" data-shape-position="bottom">
                <img alt="round-shape" src={RoundShape} className="svg-inject" />
            </div>
        </section>
    </SbEditable>
);

export default RoundedHeader;
