import React from 'react';
import SbEditable from 'storyblok-react';
import BackgroundImage from 'gatsby-background-image';
import { StaticQuery, graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';

const TeaserTwo = ({ blok }) => (
    <StaticQuery
        query={graphql`
            query {
                desktop: file(relativePath: { eq: "bg_teaser_two.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 4160) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `}
        render={data => {
            const imageData = data.desktop.childImageSharp.fluid;
            return (
                <SbEditable content={blok}>
                    <BackgroundImage className="slice slice-lg bg-dark bg-cover bg-size--cover" fluid={imageData} Tag="section">
                        <span className="mask bg-dark opacity-9"></span>
                        <div className="container">
                            <div className="row row-grid">
                                <div className="col-lg-6">
                                    <h4 className="text-white mb-4">{blok.heading}</h4>
                                    <ReactMarkdown source={blok.text} className="lead text-white lh-180 mb-4" />
                                </div>
                            </div>
                        </div>
                    </BackgroundImage>
                </SbEditable>
            );
        }}
    />
);

export default TeaserTwo;
