import React from 'react';
import SbEditable from 'storyblok-react';
import Components from '../components';

export default class Contact extends React.Component {
    render() {
        const { blok } = this.props;
        return (
            <SbEditable content={blok}>
                <div className="contact">
                    {blok.contact && blok.contact.map(blok => React.createElement(Components(blok.component), { key: blok._uid, blok: blok }))}
                </div>
            </SbEditable>
        );
    }
}
