import React from 'react';
import SbEditable from 'storyblok-react';
import ReactMarkdown from 'react-markdown';

const Impressum = ({ blok }) => (
    <SbEditable content={blok}>
        <section className="slice">
            <div className="container">
                <div className="row">
                    <div className="col mb-5 text-center" />
                </div>
                <div className="row row-grid">
                    <div className="col-lg-4">
                        <h4>{blok.address_heading}</h4>
                    </div>
                    <div className="col-lg-8">
                        <span className="lead lh-180">
                            <ReactMarkdown source={blok.address_text} />
                        </span>
                    </div>
                </div>
                <div className="row row-grid">
                    <div className="col-lg-4">
                        <h4>{blok.data_heading}</h4>
                    </div>
                    <div className="col-lg-8">
                        <span className="lead lh-180">
                            <ReactMarkdown source={blok.data_text} />
                        </span>
                    </div>
                </div>
                <div className="row row-grid">
                    <div className="col-lg-4">
                        <h4>{blok.paragraph_heading}</h4>
                    </div>
                    <div className="col-lg-8">
                        <span className="lead lh-180">
                            <ReactMarkdown source={blok.paragraph_text} />
                        </span>
                    </div>
                </div>
            </div>
        </section>
    </SbEditable>
);

export default Impressum;
