import React from 'react';
import SbEditable from 'storyblok-react';
import Components from '../components';

export default class Footer extends React.Component {
    render() {
        const { blok } = this.props;
        return (
            <SbEditable content={blok}>
                <div className="footer-container">
                    {blok.footer && blok.footer.map(blok => React.createElement(Components(blok.component), { key: blok._uid, blok: blok }))}
                </div>
            </SbEditable>
        );
    }
}
