import React from 'react';
import SbEditable from 'storyblok-react';
import Logo from '../../images/201_logo_white.svg';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';

const thisYear = new Date();

const FooterItems = ({ blok }) => (
    <SbEditable content={blok}>
        <footer className="footer footer-dark bg-gradient-primary">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 mb-5 mb-lg-0">
                        <Link to="/">
                            <img src={Logo} alt="Footer logo" style={{ height: 120 }} />
                        </Link>
                        <p className="text-sm">{blok.info_text}</p>
                    </div>
                    {blok.nav_grp.map(item => (
                        <div key={item._uid} className="col-lg-2 col-6 col-sm-4 mr-lg-auto mb-5 mb-lg-0">
                            <h6 className="heading mb-3">{item.heading}</h6>
                            <div>
                                <ReactMarkdown className="footer-markdown" source={item.text} />
                            </div>
                            <ul className="list-unstyled">
                                {item.nav_items.map(nav_item => (
                                    <React.Fragment key={nav_item._uid}>
                                        {nav_item.link === undefined ? null : (
                                            <li key={nav_item.link._uid}>
                                                <Link to={`/${nav_item.link.cached_url}`}>{nav_item.name}</Link>
                                            </li>
                                        )}
                                    </React.Fragment>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
                <div className="row align-items-center justify-content-md-between py-4 mt-4 delimiter-top">
                    <div className="col-md-6">
                        <div className="copyright text-sm font-weight-bold text-center text-md-left">
                            &copy; {thisYear.getFullYear()}
                            <Link to="/" className="font-weight-bold ml-1">
                                {blok.copyright}
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <ul className="nav justify-content-center justify-content-md-end mt-3 mt-md-0">
                            {blok.footer_social_media.map(icon => (
                                <li key={icon._uid} className="nav-item">
                                    <a
                                        className="nav-link"
                                        href={`http://${icon.link}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        aria-label="link to external site">
                                        <i className={`${icon.icon}`} />
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </SbEditable>
);

export default FooterItems;
