import React from 'react';
import SbEditable from 'storyblok-react';
import ReactMarkdown from 'react-markdown';
import { FluidImage } from './image';
import { Link } from 'gatsby';

const Team = ({ blok }) => {
    return (
        <SbEditable content={blok}>
            <section className="slice slice-lg bg-section-secondary">
                <div className="container">
                    <div className="mb-5 text-center">
                        <h3 className=" mt-4">{blok.heading}</h3>
                        <div className="fluid-paragraph mt-3">
                            <ReactMarkdown source={blok.subheading} className="lead lh-180" />
                        </div>
                    </div>
                    <div className="row row-grid">
                        {blok.mitarbeiter.map(mitarbeiter => (
                            <div key={mitarbeiter._uid} className="col-lg-3">
                                <div data-animate-hover="2">
                                    <div className="animate-this">
                                        {mitarbeiter.img && (
                                            <FluidImage attributes={{ quality: 75, maxWidth: 200 }} className="team-member" img={mitarbeiter.img} />
                                        )}
                                    </div>
                                    <div className="mt-3">
                                        <h5 className="card-title mb-0">{mitarbeiter.name}</h5>
                                        <p className="text-muted mb-4">{mitarbeiter.aufgabe}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="text-center mt-5">
                    <Link to="/jobs" className="text-black-50">
                        <i className="fas fa-angle-right mr-2"></i>Wir suchen Verstärkung
                    </Link>
                </div>
            </section>
        </SbEditable>
    );
};

export default Team;
