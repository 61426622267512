import React from 'react';
import SbEditable from 'storyblok-react';
import { Link } from 'gatsby';
import Logo from '../../images/201_logo_white.svg';

// import FlagDE from '../../images/icons/flags/de.svg';
// import FlagEN from '../../images/icons/flags/gb.svg';

export default class NavItems extends React.Component {
    state = {
        navCollapsed: true,
        languagePickerCollapsed: true,
    };

    navToggle = () => this.setState({ navCollapsed: !this.state.navCollapsed });
    languageNavToggle = () => this.setState({ languagePickerCollapsed: !this.state.languagePickerCollapsed });

    render() {
        const { blok } = this.props;

        // const collapsed = this.state.navCollapsed;

        // const languagePickerCollapsed = this.state.languagePickerCollapsed;
        // const languageSelect = languagePickerCollapsed ? 'd-none' : 'd-block dropdown-menu dropdown-menu-sm';
        // const language = 'German';

        const navLinks = blok.nav_links || [];

        return (
            <SbEditable content={blok}>
                <div className="position-relative">
                    <nav className="navbar navbar-main navbar-transparent navbar-dark bg-transparent position-absolute w-100">
                        <div className="container px-lg-0">
                            <Link aria-label="Homepage" className="navbar-brand mr-5 border-dark" to="/">
                                <img alt="201CREATED GmbH" src={Logo} style={{ height: 50 }} />
                            </Link>

                            {navLinks.length > 0 && (
                                <ul className="navbar-nav mr-auto flex-row">
                                    {navLinks
                                        .filter(nav_link => nav_link !== undefined)
                                        .map(nav_link => (
                                            <li key={nav_link._uid} className="nav-item pr-3">
                                                <Link className="nav-link" to={`/${nav_link.link.cached_url}`}>
                                                    {nav_link.name}
                                                </Link>
                                            </li>
                                        ))}
                                    {/*// this will go live if we will start translate*/}
                                    {/*<div className="col-auto p-0 ml-lg-auto">*/}
                                    {/*<ul className="nav navbar-nav">*/}
                                    {/*<li className="nav-item dropdown ml-lg-2">*/}
                                    {/*<Link to="/" onClick={this.languageNavToggle} className="nav-link px-0">*/}
                                    {/*<img alt="" src={language === 'German' ? FlagDE : FlagEN} />*/}
                                    {/*<span className="d-none d-lg-inline-block">{language}</span>*/}
                                    {/*<span className="d-lg-none">{language === 'German' ? 'DE' : 'EN'}</span>*/}
                                    {/*</Link>*/}
                                    {/*<div className={`${languageSelect}`}>*/}
                                    {/*{language === 'German' ? (*/}
                                    {/*<Link to="/en/" className="dropdown-item">*/}
                                    {/*<img alt="" src={FlagEN} />*/}
                                    {/*English*/}
                                    {/*</Link>*/}
                                    {/*) : (*/}
                                    {/*<Link to="/" className="dropdown-item">*/}
                                    {/*<img alt="" src={FlagDE} />*/}
                                    {/*German*/}
                                    {/*</Link>*/}
                                    {/*)}*/}
                                    {/*</div>*/}
                                    {/*</li>*/}
                                    {/*</ul>*/}
                                    {/*</div>*/}
                                </ul>
                            )}
                        </div>
                    </nav>
                </div>
            </SbEditable>
        );
    }
}
