import React from 'react';

const ExternalLink = ({ icon, name, btnColor, text, link }) => {
    return (
        <a href={link} className={`btn-${btnColor} ${icon ? `btn-icon` : ``} btn rounded-pill hover-translate-y-n3 mr-sm-4 scroll-me mb-1`}>
            {icon && (
                <span className="btn-inner--icon">
                    <i className={icon} />
                </span>
            )}
            <span className="btn-inner--text">{text}</span>
        </a>
    );
};

export default ExternalLink;
